const Portfolio = () => {
    const folios = [
        { href: "https://mith.io/", src: "mith.png", name: "Mithril" },
        { href: "https://www.originprotocol.com/en", src: "origin.png", name: "Origin Protocol" },
        { href: "https://www.tari.com/", src: "tari.png", name: "Tari" },
        { href: "https://b.tc/", src: "btc inc.png", name: "BTC Inc" },
        { href: "https://numer.ai/", src: "numerai.png", name: "Numerai" },
        { href: "https://www.immutable.com/", src: "Immutable.png", name: "Immutable" },
        { href: "https://www.theblockcrypto.com/", src: "block.png", name: "The Block" },
        { href: "https://urbit.org/", src: "urbit.png", name: "Urbit" },
        { href: "https://mainframe.com/", src: "mainframe.png", name: "Mainframe" },
        { href: "https://www.thundertoken.com/", src: "thunder.png", name: "Thunder" },
        { href: "https://contentbox.one/", src: "contentbox.png", name: "ContentBox" },
        { href: "https://www.pendle.finance/", src: "Pendle-Finance.png", name: "Pendle Finance" },
        { href: "https://casperlabs.io/en/", src: "casperLabs.png", name: "CasperLabs" },
        { href: "https://naos.finance", src: "NAOS Finance.png", name: "NAOS Finance" },
        { href: "https://onomy.io/", src: "Onomy.png", name: "Onomy" },
        { href: "https://forkast.news/", src: "forkast.png", name: "Forkast News" },
        { href: "https://hodooi.com/", src: "Hodooi.png", name: "Hodooi" },
        { href: "https://shima.capital/", src: "Shima-Capital.png", name: "Shima Capital" },
        { href: "https://bullperks.com/", src: "Bull-Perks.png", name: "Bull Perks" },
        { href: "https://kronosresearch.com/", src: "Kronos-Research.png", name: "Kronos Research" },
        { href: "https://plair.life/", src: "plair.png", name: "Plair" },
        { href: "https://www.openfuture.io/", src: "open.png", name: "Open" },
        { href: "https://www.blockhy.pe/", src: "blockhype.png", name: "Blockhype" },
        { href: "http://www.longkuicj.com/", src: "dragon.png", name: "Sunrise Dragon" },
        { href: "https://www.guildofguardians.com/", src: "guildOfGuardians.png", name: "Guild of Guardians" },
        { href: "https://meme.com/", src: "memecoin.png", name: "Memecoin" },
        { href: "https://only1.io/", src: "only1.png", name: "Only1" },
        { href: "https://riperdefi.com/", src: "riperDefi.png", name: "Riper Defi" },
        { href: "https://www.strips.finance/", src: "strips.png", name: "Strips Finance" },
        { href: "https://citadao.io/", src: "citadao.png", name: "CitaDAO" },
    ]
    var styl;
    if (window.innerWidth > 570) styl = "hovera";

    return (
        <div className="row featurette" id="portfolio">
            <div className="col-md-12">
                <h2 className="featurette-heading mb-3 subTit">
                    <span className="text-primary">Portfolio</span>
                </h2>
                <div className="album">
                    <div className="container">
                        <div className="row">
                            {folios.map((a, i) =>
                                <div className='col-md-4 col-sm-6' key={`portfoliod${i}`}>
                                    <div className={`card inner shadow-sm ${styl}`} onClick={() => window.open(a.href, "_blank")}>
                                        <div className="logoAlign">
                                            <div className="mb-4">
                                                <img className="img-fluid mt-3" src={`resources/portfolio/${a.src}`} alt={`${a.name} logo`} />
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        <span className="font-weight-bold text-primary">{a.name}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio;