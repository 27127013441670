import { useState } from "react";
import Details from "./Details";
import Modal from "@material-ui/core/Modal";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const Team = () => {
    const team = [
        {
            src: "jason_fang.jpg", name: "Jason Fang", design: "Managing Partner",
            desc: `Jason leads the venture investments and strategies
        at Sora Ventures. Prior to Sora, Jason was the
        Head of Global Business Development at Juzix (now
        PlatON), a leading blockchain company led by
        Wanxiang Holdings that focused on distributed
        ledger technology and multi-party computation.
        Jason joined the blockchain industry in early 2016
        as an Investment Associate at Fenbushi Capital,
        Asia’s first blockchain venture capital firm where
        he managed dealflows and post management of
        companies in North America. He also founded Sora
        Foundation, an initiative that consists of
        blockchain development, community development, and
        Sora Summit. Jason is also an active mentor at
        Alchemist Accelerator in Silicon Valley and
        Startupbootcamp in China.` },
        {
            src: "Jack_Zhou.jpg", name: "Jack Zhou", design: "Associate",
            desc: `Jack is an Associate at Sora Ventures. At the earlier 2017, Jack start his personal
        investment on Blockchain assets. By the same time, he was writing a blockchain investments
        blog, running a small ETH mining farm, and managing several blockchain community groups.
        Before Sora, Jack works as a software engineer. He was the tech leader of Eillem, a
        decentralized market place base on Ethereum. Jack graduate from Boston University with a
        Bachelor degree of Computer Science.` },
    ];

    const [show, setShow] = useState(Array(team.length).fill().map(a => false));

    const hideModal = () => {
        setShow(Array(team.length).fill().map(a => false));
    }

    const showModal = (i) => {
        show[i] = true;
        setShow([...show]);
    }

    var styl;
    if (window.innerWidth > 570) styl = "hovera";


    return (
        <div className="row featurette" id="team">
            <div className="col-md-12">
                <h2 className="featurette-heading mb-3 subTit">
                    <span className="text-primary">Team</span>
                </h2>
                <div className="album">
                    <div className="container">
                        <div className="row">
                            <div className="row adaptw">
                                {team.map((a, i) =>
                                    <div className="col-md-3 col-sm-6" key={`teamd${i}`} >
                                        <div className={`card mb-2 shadow-sm profile-card ${styl}`} onClick={() => showModal(i)}>
                                            <img className="img-fluid profile-img" src={`resources/team/${a.src}`} alt="profile pic" />
                                            <div className="card-body">
                                                <p className="card-text">
                                                    <span className="font-weight-bold text-primary">{a.name}&nbsp;</span>
                                                    <br />
                                                    <span className="text-muted jobTit">{a.design}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <Modal
                                            open={show[i]}
                                            onClose={hideModal}
                                            closeAfterTransition
                                            BackdropComponent={Backdrop}
                                            BackdropProps={{
                                                timeout: 500,
                                            }}
                                            style={{ overflow: "auto" }}
                                        >

                                            <Fade in={show[i]}>
                                                <Details hideModal={hideModal} team={team[i]} />
                                            </Fade>
                                        </Modal>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team;