import { useState, useEffect } from "react";

const Navbar = () => {
    const [state, setState] = useState({ smart: false });

    const navs = [
        { name: "About", href: "#about" },
        { name: "Portfolio", href: "#portfolio" },
        { name: "Team", href: "#team" },
        { name: "Sora Summit", href: "http://sorasummit.com/", target: "_blank" },
        { name: "Contact", href: "#contact" },
    ]

    var styl;
    if (window.innerWidth > 570) styl = "hoveran";

    return (
        <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top" style={{ alignContent: "start" }}>
            <a href="#" style={{ marginBottom: state.smart ? "5%" : "0%" }}>
                <img id="company-logo" src="/resources/soralogotop.png" alt="Sora Ventures logo" /></a>
            {state.smart ?
                <button className="navbar-toggler" style={{ width: "20%", marginBottom: "5%" }} onClick={() => { state.smart = false; setState({ smart: false }) }}>
                    <span className="" style={{ fontSize: "25px" }}>X</span>
                </button> :
                <button className="navbar-toggler" onClick={() => { state.smart = true; setState({ smart: true }) }}>
                    <span className="navbar-toggler-icon"></span>
                </button>
            }
            <div className="collapse navbar-collapse" id="navbarsExampleDefault" style={{ display: state.smart ? "flex" : "", justifyContent: "center", lineHeight: state.smart ? "2" : "" }}>
                <ul className="navbar-nav " style={{ marginLeft: state.smart ? "unset" : "auto", justifyContent: "center", textAlignLast: "center" }} >
                    {navs.map((a, i) =>
                        <li className="nav-item ml-auto" key={`nav${i}`} style={{ marginLeft: state.smart ? "unset" : "auto", textAlignLast: "center", alignContent: "center", alignSelf: "center", justifyContent: "center" }} onClick={() => { state.smart = false; setState({ ...state }) }}>
                            <a className="nav-link headernav" href={a.href} target={a.target}>{a.name}</a>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;