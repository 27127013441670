const About = () => {
    return (
        <>
            <div className="row featurette" id="about">
                <div className="col-md-10 mx-auto">
                    <h2 className="featurette-heading mb-3"> </h2>
                    <p id="description-body">
                        Founded in early 2018, Sora Ventures is Asia’s leading venture
                        capital firm dedicated to digital asset and blockchain
                        investments. The firm adopts an entirely new venture model
                        designed to scale blockchain community and help transition
                        blockchain projects into the business world.
                    </p>
                </div>
            </div>
            <hr className="featurette-divider my-5" />
            <div className="row featurette">
                <div className="col-md-10 mx-auto">
                    <h2 className="featurette-heading mb-3"> </h2>
                    <p id="description-body">
                        <img src="resources/sorapic.png" alt="Sora Brief" />
                    </p>
                </div>
            </div>
            <hr className="featurette-divider my-5" />
        </>
    )
}

export default About;