const TitleImage = () => {
    return (
        <div className="mb-3" id="top-img">
            <div className="dim-box text-light d-flex align-items-center text-center" id="top-titles">
                <h1 className="display-4 mx-auto" id="top-main-title">
                    <span className="text bannerTit">SORA VENTURES</span>
                    <p className="lead" id="top-sub-title">
                        <span className="text subText">Capital<i>|</i>Community Development<i>|</i>Advisory</span>
                    </p>
                </h1>
            </div>
        </div>
    )
}

export default TitleImage;