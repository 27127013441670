import { useState } from "react";
import Subscribe from "./Subscribe";
import Modal from "@material-ui/core/Modal";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const Contact = () => {
    const [show, setShow] = useState(false);

    const hideModal = () => {
        setShow(false);
    }

    return (
        <div className="row featurette" id="contact">
            <div className="col-md-12">
                <h2 className="featurette-heading mb-3 subTit" style={{ marginTop: "3%" }}>
                    <span className="text-primary">Contact</span>
                </h2>
                <p id="contact-body">
                    Email:&nbsp;
                    <a className="fontBlu" href="mailto:contact@sora.vc">contact@sora.vc</a>
                </p>
                <p>
                    <button className="fontBlu" onClick={() => setShow(true)}>
                        Subscribe
                    </button>
                    &nbsp;to our email list for events and news.
                </p>
                <Modal
                    open={show}
                    onClose={hideModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{ overflow: "auto", padding: "0" }}
                >

                    <Fade in={show}>
                        <Subscribe setShow={setShow} />
                    </Fade>
                </Modal>
                <p className="mt-5 text-muted">
                    Copyright © 2022 Sora Ventures | All rights reserved
                </p>
            </div>
        </div>
    )
}

export default Contact;