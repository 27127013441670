import { forwardRef } from "react";

const Details = forwardRef(({ team, hideModal }, ref) => {
    return (
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="ModalLongTitle">
                        {team.name}
                        <div className="text-muted jobTit">
                            {team.design}
                        </div>
                    </h5>
                    <button style={{ border: "0px", background: "white", fontSize: "25px" }} onClick={() => hideModal()}>
                        <span className="font-weight-normal">x</span>
                    </button>
                </div>
                <div className="modal-body">{team.desc}</div>
            </div>
        </div>
    );
})

export default Details;