import { forwardRef, useState } from "react";

const Subscribe = forwardRef(({ setShow }, ref) => {

    const [form, setForm] = useState({ fname: "", lname: "", email: "", cmp: "", loc: "" });

    return (
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="ModalLongTitle">
                        Subscription
                        <div className="text-muted">
                            You will receive news and events from Sora Ventures
                        </div>
                    </h5>
                    <button className="close" style={{ display: "contents" }} onClick={() => setShow(false)}>
                        <span className="font-weight-normal" style={{ color: "black" }}>x</span>
                    </button>
                </div>
                <div className="modal-body subscribe-con">
                    <div id="mc_embed_signup" className="form-wrapper squeezed w-form">
                        <form
                            action="https://sora.us17.list-manage.com/subscribe/post?u=41086d407594f0fc0b1b03483&amp;id=7c1afb62ca"
                            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
                            target="_blank" novalidate>
                            <div id="mc_embed_signup_scroll">
                                <div className="mc-field-group">
                                    <label htmlFor="mce-FNAME" className="form-label">First Name
                                    </label>
                                    <input type="text" value={form.fname} name="FNAME" className="form-field w-input" id="mce-FNAME" onChange={(event) => { form.fname = event.target.value; setForm({ ...form }) }} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-LNAME" className="form-label">Last Name
                                    </label>
                                    <input type="text" value={form.lname} name="LNAME" className="form-field w-input" id="mce-LNAME" onChange={(event) => { form.lname = event.target.value; setForm({ ...form }) }} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-EMAIL" className="form-label">Email Address
                                    </label>
                                    <input type="email" value={form.email} name="EMAIL" className="required email form-field w-input"
                                        id="mce-EMAIL" onChange={(event) => { form.email = event.target.value; setForm({ ...form }) }} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-MMERGE3" className="form-label">Company Name
                                    </label>
                                    <input type="text" value={form.cmp} name="MMERGE3" className="form-field w-input" id="mce-MMERGE3" onChange={(event) => { form.cmp = event.target.value; setForm({ ...form }) }} />
                                </div>
                                <div className="mc-field-group">
                                    <label htmlFor="mce-MMERGE4" className="form-label">Location (City, Country)
                                    </label>
                                    <input type="text" value={form.loc} name="MMERGE4" className="form-field w-input" id="mce-MMERGE4" onChange={(event) => { form.loc = event.target.value; setForm({ ...form }) }} />
                                </div>
                                <div style={{ position: "absolute", left: "-5000px" }}>
                                    <input type="text" name="b_41086d407594f0fc0b1b03483_7c1afb62ca" tabIndex="-1" />
                                </div>
                                <div className="clear">
                                    <a href="mailto:contact@sora.vc">
                                        <button type="submit" name="subscribe" id="mc-embedded-subscribe"
                                            className="button full-width w-button">Subscribe</button>
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default Subscribe;