import Navbar from './Components/Navbar';
import TitleImage from './Components/TitleImage';
import About from './Components/About';
import Portfolio from './Components/Portfolio';
import Team from './Components/Team';
import Contact from './Components/Contact';
import { useEffect } from 'react';

const App = () => {
    useEffect(()=>{
        let url = window.location.href;
        let parts = url.split("/");
        parts.length -= 1;
        parts.shift(1);
        let PUBLIC_URL = ""
        parts.map(a=> PUBLIC_URL+=a+="/");
    },[])

    return <>
        <Navbar />
        <TitleImage />
        <main className="container mt-5" role="main">
            <About />
            <Portfolio />
            <hr className="featurette-divider my-5" />
            <Team />
            <hr className="featurette-divider my-5" />
            <Contact />
        </main>

    </>;
}

export default App;